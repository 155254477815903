"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApartmentStatusTrans = exports.ApartmentStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _ApartmentStatusTrans;
var ApartmentStatus;
exports.ApartmentStatus = ApartmentStatus;
(function (ApartmentStatus) {
  ApartmentStatus["Empty"] = "EMPTY";
  ApartmentStatus["Fill"] = "FILL";
  ApartmentStatus["Block"] = "BLOCK";
})(ApartmentStatus || (exports.ApartmentStatus = ApartmentStatus = {}));
var ApartmentStatusTrans = (_ApartmentStatusTrans = {}, (0, _defineProperty2.default)(_ApartmentStatusTrans, ApartmentStatus.Empty, 'Phòng trống'), (0, _defineProperty2.default)(_ApartmentStatusTrans, ApartmentStatus.Fill, 'Đang thuê'), (0, _defineProperty2.default)(_ApartmentStatusTrans, ApartmentStatus.Block, 'Bị khóa'), _ApartmentStatusTrans);
exports.ApartmentStatusTrans = ApartmentStatusTrans;