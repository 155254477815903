var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.dialogStatus == "update" ? "Cập nhật tin tức" : "Thêm tin tức",
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "15px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tiêu đề", prop: "title" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.formData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "title", $$v)
                              },
                              expression: "formData.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Toà nhà", prop: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.formData.buildingIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "buildingIds", $$v)
                                },
                                expression: "formData.buildingIds",
                              },
                            },
                            _vm._l(_vm.buildings, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Thumbnail (2:1)",
                            prop: "thumbnail",
                          },
                        },
                        [
                          _c("single-image", {
                            attrs: {
                              imagePath: _vm.formData.thumbnail,
                              uploadUrl: "/v1/admin/news/upload",
                            },
                            on: { success: _vm.handleUploadSuccess },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mô tả", prop: "body" } },
                        [
                          _c("Tinymce", {
                            ref: "editor",
                            attrs: { width: "100%", height: 400 },
                            on: { onInit: _vm.setBody },
                            model: {
                              value: _vm.formData.body,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "body", $$v)
                              },
                              expression: "formData.body",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }