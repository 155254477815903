var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        width: "1200px",
        top: "15px",
        "show-close": false,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tiêu đề", prop: "title" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.contentDefine.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.contentDefine, "title", $$v)
                          },
                          expression: "form.contentDefine.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mô tả", prop: "body" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { width: "100%", height: 400 },
                        on: { onInit: _vm.setBody },
                        model: {
                          value: _vm.form.contentDefine.body,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.contentDefine, "body", $$v)
                          },
                          expression: "form.contentDefine.body",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }