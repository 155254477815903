"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var customerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/customer",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/customer",
      method: "post",
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(id, "/update"),
      method: "post",
      data: data
    });
  },
  unBlock: function unBlock(id) {
    return (0, _request.default)({
      url: "/v1/admin/customer/".concat(id, "/unblock"),
      method: "post"
    });
  },
  affiliate: function affiliate(params) {
    return (0, _request.default)({
      url: "/v1/admin/customer/affiliate/children",
      params: params
    });
  }
};
exports.customerApi = customerApi;