var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Mã hợp đồng", prop: "contract.code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", [_vm._v(_vm._s(row.contract.code))]),
                    _c("br"),
                    _c("i", [_vm._v(_vm._s(_vm.$formatDate(row.createdAt)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customer.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", [_vm._v(" " + _vm._s(row.customer.name))]),
                    _vm._v(" (" + _vm._s(row.customer.code) + ") "),
                    _c("br"),
                    _c("i", { staticClass: "el-icon-phone" }),
                    _vm._v(" " + _vm._s(row.customer.phone) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phòng", prop: "assignedStaff.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.customer.apartments[0].code) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Ghi chú", prop: "note" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }