"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
_vue.default.use(_vueRouter.default);
/* Layout */

/* Router Modules */
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth/index.vue"));
    });
  },
  name: "auth",
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/building",
  hidden: true
}, {
  path: "/dashboard",
  component: _index.default,
  hidden: true,
  meta: {
    title: "Dashboard",
    icon: "chart",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index.vue"));
      });
    },
    name: "dashboard-index",
    meta: {
      title: "Dashboard",
      permission: true
    }
  }]
}, {
  path: "/building",
  component: _index.default,
  redirect: "/building/index",
  meta: {
    title: "Quản lý tòa nhà",
    icon: "building",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/building/index.vue"));
      });
    },
    name: "building-index",
    meta: {
      title: "Tòa nhà",
      permission: true
    }
  }]
}, {
  path: "/apartment",
  component: _index.default,
  redirect: "/apartment",
  meta: {
    title: "Phòng, căn hộ",
    icon: "home",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/apartment/index.vue"));
      });
    },
    name: "apartment-index",
    meta: {
      title: "Phòng, căn hộ",
      permission: true
    }
  }]
}, {
  path: "/customer",
  component: _index.default,
  redirect: "/customer",
  meta: {
    title: "Khách hàng",
    icon: "peoples",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index.vue"));
      });
    },
    name: "customer-index",
    meta: {
      title: "Khách hàng",
      permission: true
    }
  }]
}, {
  path: "/contract",
  component: _index.default,
  redirect: "/contract",
  meta: {
    title: "Hợp đồng",
    icon: "peoples",
    permission: true
  },
  children: [{
    path: "official",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/contract/index.vue"));
      });
    },
    name: "contract-official",
    meta: {
      title: "Hợp đồng chính thức",
      permission: true
    }
  }, {
    path: "prepay",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/contract-prepay/index.vue"));
      });
    },
    name: "contract-prepay",
    meta: {
      title: "Hợp đồng đặt cọc",
      permission: true
    }
  }]
},
// {
//   path: "/service",
//   component: Layout,
//   redirect: "/service",
//   meta: {
//     title: "Tiện ích",
//     icon: "dice-d6",
//     permission: true,
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/building-service/index.vue"),
//       name: "service-index",
//       meta: {
//         title: "Tiện ích",
//         permission: true,
//       },
//     },
//   ],
// },
{
  path: "/news",
  component: _index.default,
  meta: {
    title: "Tin tức",
    icon: "dice-d6",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/index.vue"));
      });
    },
    name: "news-index",
    meta: {
      title: "Tin tức",
      permission: true
    }
  }]
}, {
  path: "/content",
  component: _index.default,
  meta: {
    title: "Nội dung (App)",
    icon: "newspaper",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content/index.vue"));
      });
    },
    name: "content-index",
    meta: {
      title: "Nội dung (App)",
      permission: true
    }
  }]
}, {
  path: "/banner",
  component: _index.default,
  meta: {
    title: "Banner (App)",
    icon: "dice-d6",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner/index.vue"));
      });
    },
    name: "banner-index",
    meta: {
      title: "Banner (App)",
      permission: true
    }
  }]
}, {
  path: "/staff",
  component: _index.default,
  redirect: "/staff/index",
  meta: {
    title: "Nhân viên",
    icon: "briefcase",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/staff/index.vue"));
      });
    },
    name: "staff-employees",
    meta: {
      title: "Nhân viên",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    title: "Profile",
    icon: "qq",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "profile",
      permission: false
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.constantRoutes = constantRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
}
var _default = router;
exports.default = _default;