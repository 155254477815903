var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-form" }, [
    _c(
      "div",
      { staticClass: "login-container" },
      [
        _vm.formType == "login"
          ? [
              _c("span", { staticClass: "login-for-booker" }, [
                _vm._v("Đăng nhập"),
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "sign-in-form",
                  staticStyle: { "margin-top": "24px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên đăng nhập", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { size: "medium" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSubmit($event)
                          },
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "8px" },
                      attrs: { label: "Mật khẩu", prop: "password" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: _vm.showPass ? "text" : "password",
                          size: "medium",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSubmit($event)
                          },
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "show-pwd" },
                        [
                          !_vm.showPass
                            ? _c("font-awesome-icon", {
                                attrs: { icon: "eye" },
                                on: {
                                  click: function ($event) {
                                    _vm.showPass = true
                                  },
                                },
                              })
                            : _c("font-awesome-icon", {
                                attrs: { icon: "eye-slash" },
                                on: {
                                  click: function ($event) {
                                    _vm.showPass = false
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              false
                ? _c(
                    "a",
                    {
                      staticClass: "forgot-label",
                      on: {
                        click: function ($event) {
                          _vm.formType = "forgot"
                        },
                      },
                    },
                    [_vm._v("Forgot password?")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "btn-login",
                  attrs: { type: "primary", loading: _vm.loadingSubmit },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" Đăng nhập ")]
              ),
            ]
          : _vm.formType == "reset"
          ? _c("re-password-form", {
              attrs: { email: _vm.emailReset, resetCode: _vm.resetCode },
              on: {
                back: function ($event) {
                  _vm.formType = "login"
                },
              },
            })
          : _vm.formType == "forgot"
          ? _c("forgot-form", {
              on: {
                "back-login": function ($event) {
                  _vm.formType = "login"
                },
              },
            })
          : _vm.formType == "otp"
          ? _c("otp-form", {
              attrs: { staffId: _vm.staffId },
              on: {
                "back-forgot": function ($event) {
                  _vm.formType = "login"
                },
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }