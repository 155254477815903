"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findOne = findOne;
exports.staffApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var staffApi = {
  reset: reset,
  findOne: findOne,
  findAll: findAll,
  create: create,
  update: update,
  remove: remove,
  block: function block(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId, "/block"),
      method: "post"
    });
  },
  unblock: function unblock(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId, "/unblock"),
      method: "post"
    });
  },
  delete: function _delete(staffId) {
    return (0, _request.default)({
      url: "/v1/admin/staff/".concat(staffId),
      method: 'delete'
    });
  }
};
exports.staffApi = staffApi;
function reset(id, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(id, "/password/reset"),
    method: "post",
    data: data
  });
}
function findOne(userId) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId),
    method: "get"
  });
}
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/v1/admin/staff",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/v1/admin/staff",
    method: "post",
    data: data
  });
}
function update(userId, data) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId, "/update"),
    method: "post",
    data: data
  });
}
function remove(userId) {
  return (0, _request.default)({
    url: "/v1/admin/staff/".concat(userId, "/delete"),
    method: "delete"
  });
}