var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-professional" }, [
      _c("p", [_vm._v("Booking as a customer? ")]),
      _c("div", { staticClass: "content" }, [
        _c("span", [_vm._v("This is the partner area, please go to")]),
        _c(
          "a",
          {
            staticClass: "link",
            attrs: { href: "https://", target: "_blank" },
          },
          [_vm._v(" booker log in ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }