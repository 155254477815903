var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("building-add-service-modal", {
        ref: "BuildingAddServiceModal",
        attrs: { buildingId: _vm.buildingId },
        on: { "submit:ok": _vm.onSubmitOk },
      }),
      _c("building-service-modal", {
        ref: "BuildingServiceModal",
        attrs: { buildingId: _vm.buildingId },
        on: { "submit:ok": _vm.onSubmitOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }