var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "building-service" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.buildingServices, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tên dịch vụ", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Đơn giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.price)) +
                        "/" +
                        _vm._s(row.unit) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }