var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-editor-container" }, [
    _c(
      "div",
      {
        staticClass: "filter-container",
        staticStyle: { "margin-bottom": "40px" },
      },
      [
        _c("div", { staticClass: "filter-box" }, [
          _c("div", { staticStyle: { "margin-right": "12px" } }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Loại đơn")]),
            _c("br"),
            _c(
              "div",
              { staticStyle: { "margin-top": "8px" } },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: _vm.OrderType.New },
                    model: {
                      value: _vm.orderType,
                      callback: function ($$v) {
                        _vm.orderType = $$v
                      },
                      expression: "orderType",
                    },
                  },
                  [_vm._v("Đơn mới")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: _vm.OrderType.Old },
                    model: {
                      value: _vm.orderType,
                      callback: function ($$v) {
                        _vm.orderType = $$v
                      },
                      expression: "orderType",
                    },
                  },
                  [_vm._v("Đơn cũ")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: _vm.OrderType.All },
                    model: {
                      value: _vm.orderType,
                      callback: function ($$v) {
                        _vm.orderType = $$v
                      },
                      expression: "orderType",
                    },
                  },
                  [_vm._v("Tất cả")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Chọn ngày")]),
              _c("br"),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "to",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                  "picker-options": _vm.pickerOptions,
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticStyle: { "margin-bottom": "32px" } },
      [
        _c("panel-group", {
          attrs: { orderType: _vm.orderType, date: _vm.convertedDate },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          "box-shadow":
            "#32325d40 -1px -4px 60px -20px, #0000004d -1px 1px 40px -30px",
        },
      },
      [
        _c("category-chart", {
          attrs: { orderType: _vm.orderType, date: _vm.convertedDate },
        }),
        _c("profit-chart", {
          attrs: { orderType: _vm.orderType, date: _vm.convertedDate },
        }),
        _c("order-type-chart", { attrs: { date: _vm.convertedDate } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }