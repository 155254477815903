"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
require("core-js/modules/es.array.concat.js");
var _settings = _interopRequireDefault(require("@/settings"));
var title = _settings.default.title || 'Fclass';
function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}