var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { label: "Tiêu đề", prop: "title" } }),
          _c("el-table-column", { attrs: { label: "Mô tả", prop: "body" } }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customer.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", [_vm._v(" " + _vm._s(row.customer.name))]),
                    _vm._v(" (" + _vm._s(row.customer.code) + ") "),
                    _c("br"),
                    _c("i", { staticClass: "el-icon-phone" }),
                    _vm._v(" " + _vm._s(row.customer.phone) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nhân viên đảm nhiệm" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return row.assignedStaff
                      ? [
                          _c("label", [
                            _vm._v(" " + _vm._s(row.assignedStaff.name)),
                          ]),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-phone" }),
                          _vm._v(" " + _vm._s(row.assignedStaff.phone) + " "),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.FeedbackTagOptions[row.status] &&
                            _vm.FeedbackTagOptions[row.status].type,
                          effect:
                            _vm.FeedbackTagOptions[row.status] &&
                            _vm.FeedbackTagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.FeedbackStatusTrans[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }