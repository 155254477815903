var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "building-staff" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { label: "Tên", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }