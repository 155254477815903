"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: _jsCookie.default.get('size') || 'medium'
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    _jsCookie.default.set('size', size);
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref5, size) {
    var commit = _ref5.commit;
    commit('SET_SIZE', size);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;