"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoleName = void 0;
var RoleName;
exports.RoleName = RoleName;
(function (RoleName) {
  RoleName["Admin"] = "ADMIN";
  RoleName["Dev"] = "DEV";
  RoleName["Manager"] = "MANAGER";
  RoleName["Advisor"] = "ADVISOR";
  RoleName["Sale"] = "SALE";
  RoleName["Account"] = "ACCOUNT";
  RoleName["Service"] = "SERVICE";
  RoleName["Supervisor"] = "SUPERVISOR";
})(RoleName || (exports.RoleName = RoleName = {}));