var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              staticStyle: { width: "250px" },
              attrs: { placeholder: "Tìm mã hợp đồng, khách" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleQuery($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "", filterable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "status", $$v)
                  },
                  expression: "query.status",
                },
              },
              _vm._l(_vm.ContractStatusTrans, function (item, key) {
                return _c("el-option", {
                  key: key,
                  attrs: { label: item, value: key },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Mã hợp đồng", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.code)),
                    ]),
                    _c("br"),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleViewContract(row)
                          },
                        },
                      },
                      [_vm._v("Xem hợp đồng")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tên hợp đồng", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                    _c("br"),
                    _c("ul", { staticClass: "list-style-none" }, [
                      _c("li", [
                        _vm._v(
                          "Tiền phòng: " +
                            _vm._s(_vm.$formatNumberVN(row.apartmentPrice)) +
                            "đ"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Tiền cọc " +
                            _vm._s(_vm.$formatNumberVN(row.moneyPrepay)) +
                            "đ"
                        ),
                      ]),
                      _c("li", [
                        _vm._v("Số người: " + _vm._s(row.amountOfPeople)),
                      ]),
                      _c("li", [_vm._v("Số xe: " + _vm._s(row.numOfVehicles))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customer.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.customer.name)),
                    ]),
                    _vm._v(" " + _vm._s(" ") + " "),
                    _c("span", [_vm._v("(" + _vm._s(row.customer.code) + ")")]),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(row.customer.phone))]),
                    _c("el-divider", { staticClass: "custom-divider" }),
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Thời gian thuê"),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("span", [
                      _c("b", [_vm._v("Từ ngày:")]),
                      _vm._v(" " + _vm._s(row.fromDate || "Không xác định")),
                    ]),
                    _c("br"),
                    _c("span", [
                      _c("b", [_vm._v("Đến ngày:")]),
                      _vm._v(" " + _vm._s(row.toDate || "Không xác định")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nhân viên tạo", prop: "createdStaff.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.createdStaff.name)),
                    ]),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(row.createdStaff.phone))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.ContractTagOptions[row.status] &&
                            _vm.ContractTagOptions[row.status].type,
                          effect:
                            _vm.ContractTagOptions[row.status] &&
                            _vm.ContractTagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.ContractStatusTrans[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }