"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.invoiceApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var invoiceApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/invoice",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/invoice",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/update"),
      method: "post",
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/delete"),
      method: "post"
    });
  },
  delay: function delay(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/delay"),
      method: "post",
      data: data
    });
  },
  complete: function complete(id) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/complete"),
      method: "post"
    });
  },
  report: function report(params) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/report",
      params: params
    });
  },
  water: function water(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/water"),
      method: "put",
      data: data
    });
  },
  electric: function electric(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/electric"),
      method: "put",
      data: data
    });
  },
  publish: function publish(id) {
    return (0, _request.default)({
      url: "/v1/admin/invoice/".concat(id, "/publish"),
      method: "post"
    });
  }
};
exports.invoiceApi = invoiceApi;