"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");
var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");
var _freeBrandsSvgIcons = require("@fortawesome/free-brands-svg-icons");
var _freeRegularSvgIcons = require("@fortawesome/free-regular-svg-icons");
var _vueFontawesome = require("@fortawesome/vue-fontawesome");
_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faTruckMoving, _freeSolidSvgIcons.faExchangeAlt, _freeSolidSvgIcons.faHourglassStart, _freeSolidSvgIcons.faTasks, _freeSolidSvgIcons.faBan, _freeSolidSvgIcons.faClock, _freeSolidSvgIcons.faUserFriends, _freeSolidSvgIcons.faMapMarkerAlt, _freeRegularSvgIcons.faTimesCircle, _freeSolidSvgIcons.faArrowRight, _freeSolidSvgIcons.faThumbsUp, _freeSolidSvgIcons.faPlay, _freeSolidSvgIcons.faFileInvoice, _freeSolidSvgIcons.faTags, _freeSolidSvgIcons.faChevronLeft, _freeSolidSvgIcons.faArrowsAltV, _freeSolidSvgIcons.faFileInvoiceDollar, _freeSolidSvgIcons.faArrowLeft, _freeSolidSvgIcons.faCheck, _freeSolidSvgIcons.faBars, _freeSolidSvgIcons.faChevronRight, _freeSolidSvgIcons.faTimes, _freeSolidSvgIcons.faEye, _freeSolidSvgIcons.faEyeSlash, _freeSolidSvgIcons.faTicketAlt, _freeSolidSvgIcons.faGlobe, _freeBrandsSvgIcons.faFacebookSquare, _freeBrandsSvgIcons.faInstagram, _freeSolidSvgIcons.faStar, _freeRegularSvgIcons.faCheckCircle);
_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);