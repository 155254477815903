var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Danh sách nhân viên" } },
            [
              _c("div", { staticClass: "filter-container" }, [
                _c(
                  "div",
                  { staticClass: "filter-item" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
                    _c("el-input", {
                      nativeOn: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleQuery($event)
                        },
                      },
                      model: {
                        value: _vm.query.search,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "search", $$v)
                        },
                        expression: "query.search",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-item" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Quyền")]),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        attrs: { clearable: "", multiple: "" },
                        on: { change: _vm.handleQuery },
                        model: {
                          value: _vm.query.roleNames,
                          callback: function ($$v) {
                            _vm.$set(_vm.query, "roleNames", $$v)
                          },
                          expression: "query.roleNames",
                        },
                      },
                      _vm._l(_vm.roles, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.description, value: item.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-item" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Quản lý toà nhà"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        attrs: { clearable: "" },
                        on: { change: _vm.handleQueryStaff },
                        model: {
                          value: _vm.staffMangerId,
                          callback: function ($$v) {
                            _vm.staffMangerId = $$v
                          },
                          expression: "staffMangerId",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "Tất cả", value: null },
                        }),
                        _vm._l(_vm.staffManagers, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.buildings.length
                  ? _c(
                      "div",
                      { staticClass: "filter-item" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Toà nhà"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { clearable: "", multiple: "" },
                            on: { change: _vm.handleQuery },
                            model: {
                              value: _vm.query.buildingIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "buildingIds", $$v)
                              },
                              expression: "query.buildingIds",
                            },
                          },
                          _vm._l(_vm.buildings, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "filter-item" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "el-icon-plus",
                        },
                        on: { click: _vm.handleCreate },
                      },
                      [_vm._v("Thêm mới")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.data, fit: "" },
                  on: { "row-click": _vm.handleUpdate },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Tên", prop: "name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _c("img", {
                                staticStyle: {
                                  "vertical-align": "middle",
                                  "border-radius": "50%",
                                  "object-fit": "cover",
                                  "margin-right": "8px",
                                },
                                attrs: {
                                  width: "40",
                                  height: "40",
                                  src: row.avatar
                                    ? _vm.$url.image(row.avatar)
                                    : _vm.avatarDefault,
                                  alt: "",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticStyle: { "vertical-align": "middle" },
                                  attrs: { for: "" },
                                },
                                [_vm._v(_vm._s(row.name))]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Username", prop: "username" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Toà nhà" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "ul",
                              { staticClass: "pay" },
                              _vm._l(row.buildings, function (item) {
                                return _c("li", { key: item.id }, [
                                  _c("span", [_vm._v("Toà nhà: ")]),
                                  _c("span", { staticClass: "increase" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("span", [_vm._v(" - Quản lý: ")]),
                                  _c("span", { staticClass: "increase" }, [
                                    _vm._v(_vm._s(_vm.getStaffManager(item))),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Số điện thoại", prop: "phone" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Email", prop: "email" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Quyền", prop: "role.description" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Trạng thái" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: row.isBlock ? "danger" : "success",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.isBlock ? "Bị khóa" : "Còn hoạt động"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.query.page,
                  limit: _vm.query.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.query, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.query, "limit", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          ),
          _c("el-tab-pane", { attrs: { label: "Quyền" } }, [_c("Role")], 1),
        ],
        1
      ),
      _c("staff-modal", {
        ref: "StaffModal",
        on: { "submit:success": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }