var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: 8, xs: 24 } },
            [
              _c("div", { staticClass: "card-panel revenue" }, [
                _c("div", { staticClass: "icon-box" }, [
                  _c("img", { attrs: { src: _vm.revenueIcon, alt: "" } }),
                ]),
                _c("div", [
                  _c("label", { staticClass: "title", attrs: { for: "" } }, [
                    _vm._v("Doanh thu"),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "description" }, [
                    _vm._v(
                      _vm._s(_vm.$formatNumberVN(_vm.data.all.totalPrice)) +
                        " đ"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "title sub", attrs: { for: "" } },
                    [_vm._v("Đơn cũ: ")]
                  ),
                  _c("span", { staticClass: "description sub" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(_vm.data.old.totalPrice)) +
                        " đ (" +
                        _vm._s(_vm.data.old.totalOrder) +
                        " đơn)"
                    ),
                  ]),
                ]),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "title sub", attrs: { for: "" } },
                    [_vm._v("Đơn mới: ")]
                  ),
                  _c("span", { staticClass: "description sub" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(_vm.data.new.totalPrice)) +
                        " đ (" +
                        _vm._s(_vm.data.new.totalOrder) +
                        " đơn)"
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: 8, xs: 24 } },
            [
              _c("div", { staticClass: "card-panel profit" }, [
                _c("div", { staticClass: "icon-box" }, [
                  _c("img", { attrs: { src: _vm.profitIcon, alt: "" } }),
                ]),
                _c("label", { staticClass: "title", attrs: { for: "" } }, [
                  _vm._v("Lợi nhuận"),
                ]),
                _c("br"),
                _c("span", { staticClass: "description" }, [
                  _vm._v(
                    _vm._s(_vm.$formatNumberVN(_vm.data.all.totalProfit)) + " đ"
                  ),
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "title sub", attrs: { for: "" } },
                    [_vm._v("Đơn cũ: ")]
                  ),
                  _c("span", { staticClass: "description sub" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(_vm.data.old.totalProfit)) +
                        " đ"
                    ),
                  ]),
                ]),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "title sub", attrs: { for: "" } },
                    [_vm._v("Đơn mới: ")]
                  ),
                  _c("span", { staticClass: "description sub" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(_vm.data.new.totalProfit)) +
                        " đ"
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: 8, xs: 24 } },
            [
              _c("div", { staticClass: "card-panel percent" }, [
                _c("div", { staticClass: "icon-box" }, [
                  _c("img", { attrs: { src: _vm.percentIcon, alt: "" } }),
                ]),
                _c("label", { staticClass: "title", attrs: { for: "" } }, [
                  _vm._v("Tỉ suất LN"),
                ]),
                _c("br"),
                _c("span", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.percentAll) + "%"),
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "title sub", attrs: { for: "" } },
                    [_vm._v("Đơn cũ: ")]
                  ),
                  _c("span", { staticClass: "description sub" }, [
                    _vm._v(" " + _vm._s(_vm.percentOld) + "%"),
                  ]),
                ]),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "title sub", attrs: { for: "" } },
                    [_vm._v("Đơn mới: ")]
                  ),
                  _c("span", { staticClass: "description sub" }, [
                    _vm._v(" " + _vm._s(_vm.percentNew) + "%"),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }