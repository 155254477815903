var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "building-staff" },
    [
      _vm.customer
        ? _c("customer-item", { attrs: { customer: _vm.customer } })
        : _vm._e(),
      _c("apartment-add-customer-modal", {
        ref: "ApartmentAddCustomerModal",
        attrs: { buildingId: _vm.buildingId, apartmentId: _vm.apartmentId },
        on: { "submit:ok": _vm.onSubmitOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }