var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { data: _vm.apartments, border: "", fit: "" },
      on: { "row-click": _vm.rowClick },
    },
    [
      _c("el-table-column", {
        attrs: { label: "Thông tin phòng", prop: "code" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("label", [_vm._v(_vm._s(row.code))]),
                _vm._v(" "),
                _c("br"),
                _c("ul", { staticClass: "list-style-none" }, [
                  _c("li", [
                    _vm._v(
                      "Tiền phòng: " +
                        _vm._s(_vm.$formatNumberVN(row.price)) +
                        "đ"
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Diện tích" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v(_vm._s(row.area) + "m")]),
                _vm._v(" "),
                _c("sup", [_vm._v("2")]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Tình trạng" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("apartment-status-label", { attrs: { status: row.status } }),
                row.status == _vm.ApartmentStatus.Fill
                  ? _c("div", [
                      _c("ul", { staticClass: "list-style-none" }, [
                        _c("li", [
                          _vm._v("Số người: " + _vm._s(row.amountOfPeople)),
                        ]),
                        _c("li", [
                          _vm._v(
                            "Xe máy: " +
                              _vm._s(_vm.$formatNumberVN(row.bikeParkPrice)) +
                              "đ/xe"
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "Xe điện: " +
                              _vm._s(
                                _vm.$formatNumberVN(row.electricParkPrice)
                              ) +
                              "đ/xe"
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Tiện ích" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      row.apartmentServices
                        .map(function (e) {
                          return e.buildingService.name
                        })
                        .join(", ")
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Tòa nhà" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(
                  " " +
                    _vm._s(row.building.name) +
                    " - " +
                    _vm._s(row.building.address) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }