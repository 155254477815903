var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật khách hàng"
            : "Thêm mới khách hàng",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "info", label: "Thông tin" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Tên khách hàng", prop: "name" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Email", prop: "email" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Số điện thoại", prop: "phone" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Số CMND / CCCD",
                                prop: "identification",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.identification,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "identification", $$v)
                                  },
                                  expression: "form.identification",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Ngày cấp",
                                prop: "identificationDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "dd/MM/yyyy",
                                  "value-format": "dd/MM/yyyy",
                                },
                                model: {
                                  value: _vm.form.identificationDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "identificationDate",
                                      $$v
                                    )
                                  },
                                  expression: "form.identificationDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Địa chỉ thường trú",
                                prop: "address",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Tòa nhà",
                        required: "",
                        prop: "buildingId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.buildingId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "buildingId", $$v)
                            },
                            expression: "form.buildingId",
                          },
                        },
                        _vm._l(_vm.buildings, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Avatar", prop: "avatar" } },
                    [
                      _c("single-image", {
                        attrs: {
                          width: 200,
                          height: 100,
                          imagePath: _vm.form.avatar,
                          uploadUrl: "/v1/admin/customer/upload",
                        },
                        on: {
                          "upload:success": function (path) {
                            return _vm.$set(_vm.form, "avatar", path)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.tab == "info"
            ? [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                          },
                        },
                      },
                      [_vm._v("Đóng")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning", loading: _vm.loadingBlock },
                        on: { click: _vm.handleBlock },
                      },
                      [_vm._v("Mở khóa")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            _vm.status == "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("Đồng ý")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }