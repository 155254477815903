"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeedbackTagOptions = exports.FeedbackStatusTrans = exports.FeedbackStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _FeedbackStatusTrans, _FeedbackTagOptions;
var FeedbackStatus;
exports.FeedbackStatus = FeedbackStatus;
(function (FeedbackStatus) {
  FeedbackStatus["Pending"] = "PENDING";
  FeedbackStatus["Processing"] = "PROCESSING";
  FeedbackStatus["Complete"] = "COMPLETE";
})(FeedbackStatus || (exports.FeedbackStatus = FeedbackStatus = {}));
var FeedbackStatusTrans = (_FeedbackStatusTrans = {}, (0, _defineProperty2.default)(_FeedbackStatusTrans, FeedbackStatus.Pending, "Yêu cầu mới"), (0, _defineProperty2.default)(_FeedbackStatusTrans, FeedbackStatus.Processing, "Đang xử lý"), (0, _defineProperty2.default)(_FeedbackStatusTrans, FeedbackStatus.Complete, "Hoàn tất"), _FeedbackStatusTrans);
exports.FeedbackStatusTrans = FeedbackStatusTrans;
var FeedbackTagOptions = (_FeedbackTagOptions = {}, (0, _defineProperty2.default)(_FeedbackTagOptions, FeedbackStatus.Pending, {
  type: "",
  effect: "plain"
}), (0, _defineProperty2.default)(_FeedbackTagOptions, FeedbackStatus.Processing, {
  type: "warning",
  effect: "plain"
}), (0, _defineProperty2.default)(_FeedbackTagOptions, FeedbackStatus.Complete, {
  type: "success",
  effect: "plain"
}), _FeedbackTagOptions);
exports.FeedbackTagOptions = FeedbackTagOptions;