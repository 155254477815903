"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StopContractType = exports.FormStopContractStatus = exports.ContractTagOptions = exports.ContractStatusTrans = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _ContractStatusTrans, _ContractTagOptions;
var StopContractType;
exports.StopContractType = StopContractType;
(function (StopContractType) {
  StopContractType["Stop"] = "STOP";
  StopContractType["CheckOut"] = "CHECK_OUT";
  StopContractType["ChangeApartment"] = "CHANGE_APARTMENT";
})(StopContractType || (exports.StopContractType = StopContractType = {}));
var FormStopContractStatus;
exports.FormStopContractStatus = FormStopContractStatus;
(function (FormStopContractStatus) {
  FormStopContractStatus["New"] = "NEW";
  FormStopContractStatus["Completed"] = "COMPLETED";
})(FormStopContractStatus || (exports.FormStopContractStatus = FormStopContractStatus = {}));
var ContractStatusTrans = (_ContractStatusTrans = {}, (0, _defineProperty2.default)(_ContractStatusTrans, FormStopContractStatus.New, "Mới tạo"), (0, _defineProperty2.default)(_ContractStatusTrans, FormStopContractStatus.Completed, "Đã hoàn thành"), _ContractStatusTrans);
exports.ContractStatusTrans = ContractStatusTrans;
var ContractTagOptions = (_ContractTagOptions = {}, (0, _defineProperty2.default)(_ContractTagOptions, FormStopContractStatus.New, {
  type: "plain",
  effect: ""
}), (0, _defineProperty2.default)(_ContractTagOptions, FormStopContractStatus.Completed, {
  type: "plain",
  effect: "dark"
}), _ContractTagOptions);
exports.ContractTagOptions = ContractTagOptions;