var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-item", on: { click: _vm.onClick } },
    [
      _vm.visibleSelect
        ? _c("div", { staticClass: "select-box" }, [
            !_vm.isSelected
              ? _c("img", {
                  attrs: { width: "20", src: _vm.unCheckImg, alt: "" },
                })
              : _c("img", {
                  attrs: { width: "20", src: _vm.checkImg, alt: "" },
                }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "info" }, [
        _c("label", { staticClass: "name", attrs: { for: "" } }, [
          _vm._v(
            " " +
              _vm._s(_vm.service.name) +
              " " +
              _vm._s(!!_vm.service.unit ? "(" + _vm.service.unit + ")" : "") +
              " "
          ),
        ]),
        !_vm.visibleSelect
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$formatNumberVN(_vm.service.buildingService.price)
                  ) +
                  "đ "
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }