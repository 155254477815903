"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ruleRequired = void 0;
var ruleRequired = {
  required: true,
  message: "Field is required",
  trigger: "submit"
};
exports.ruleRequired = ruleRequired;