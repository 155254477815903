var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên ..." },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleQuery($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tòa nhà")]),
            _c(
              "el-select",
              {
                attrs: {
                  filterable: "",
                  clearable: "",
                  remote: "",
                  "remote-method": _vm.fetchData,
                },
                on: { change: _vm.handleQuery },
                model: {
                  value: _vm.buildingId,
                  callback: function ($$v) {
                    _vm.buildingId = $$v
                  },
                  expression: "buildingId",
                },
              },
              _vm._l(_vm.buildings, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: {
                    label: item.name + " - " + item.address,
                    value: item.id,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Thêm khách hàng")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
          on: { "row-click": _vm.handleUpdate },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Tên", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("img", {
                        staticStyle: {
                          "vertical-align": "middle",
                          "border-radius": "50%",
                          "object-fit": "cover",
                          "margin-right": "8px",
                        },
                        attrs: {
                          width: "40",
                          height: "40",
                          src: row.avatar
                            ? _vm.$url.image(row.avatar)
                            : _vm.avatarDefault,
                          alt: "",
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticStyle: { "vertical-align": "middle" },
                          attrs: { for: "" },
                        },
                        [_vm._v(_vm._s(row.name))]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã khách hàng", prop: "code" },
          }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
          }),
          _c("el-table-column", { attrs: { label: "Email", prop: "email" } }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ thường trú", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { label: "Phòng", prop: "apartments[0].code" },
          }),
          _c("el-table-column", {
            attrs: { label: "Toà nhà", prop: "building.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: row.isBlock ? "danger" : "success" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.isBlock ? "Bị khóa" : "Còn hoạt động") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("customer-modal", {
        ref: "CustomerModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }