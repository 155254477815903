var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm nhân viên",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return false($event)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tìm kiếm" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Tìm tên, sđt", size: "medium" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchStaffs($event)
                  },
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c(
              "section",
              [
                _c("h3", [_vm._v(" Đã chọn ")]),
                _vm._l(_vm.selectedStaffs, function (item) {
                  return _c("staff-item", {
                    key: item.id,
                    attrs: {
                      staff: item,
                      isSelected: true,
                      visibleSelect: true,
                    },
                    on: { onClick: _vm.handleClickStaff },
                  })
                }),
              ],
              2
            ),
          ]),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _vm.staffs.length
              ? _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  },
                  [
                    _c("h3", [_vm._v(" Kết quả tìm ")]),
                    _vm._l(_vm.staffs, function (item, index) {
                      return _c("staff-item", {
                        key: index,
                        attrs: {
                          staff: item,
                          visibleSelect: true,
                          isSelected:
                            _vm.selectedStaffs.findIndex(function (e) {
                              return e.id == item.id
                            }) > -1,
                        },
                        on: { onClick: _vm.handleClickStaff },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.selectedStaffs.length,
                loading: _vm.loadingSubmit,
              },
              on: { click: _vm.handleChoose },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }