var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.visibleOTP
        ? [
            _c("span", { staticClass: "forgot-password" }, [
              _vm._v("Reset your password"),
            ]),
            _c("p", { staticClass: "content" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isSentEmail
                      ? "Check your email to continue reset password"
                      : "Enter your registered email address and we'll send you a secure link to setup a new password."
                  ) +
                  " "
              ),
            ]),
            !_vm.isSentEmail
              ? _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit($event)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Email address", prop: "email" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "medium",
                            placeholder: "Enter your email address",
                          },
                          model: {
                            value: _vm.form.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "email", $$v)
                            },
                            expression: "form.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isSentEmail
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-reset",
                    attrs: { type: "primary", size: "", loading: _vm.loading },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("Reset password")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "text-center",
                staticStyle: { "margin-top": "16px" },
              },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("back-login")
                      },
                    },
                  },
                  [_vm._v("Back go login")]
                ),
              ]
            ),
          ]
        : _c("OTPForm", {
            on: {
              "back-forgot": function ($event) {
                _vm.visibleOTP = false
              },
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }