var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        width: _vm.dialogWidth,
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          on: { "tab-click": _vm.handleChangeTab },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "info", label: "Thông tin" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mã phòng", prop: "code" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "VD: P.101" },
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Diện tích (m2)", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.area,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "area", $$v)
                          },
                          expression: "form.area",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.buildingId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Tòa nhà", prop: "buildingId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.fetchBuildings,
                              },
                              model: {
                                value: _vm.form.buildingId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "buildingId", $$v)
                                },
                                expression: "form.buildingId",
                              },
                            },
                            _vm._l(_vm.buildings, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.name + " - " + item.address,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "customer", lazy: "", label: "Khách hàng" } },
                [
                  _c("apartment-customer", {
                    attrs: {
                      customer: _vm.form.customer,
                      buildingId: _vm.buildingId,
                      apartmentId: _vm.form.id,
                    },
                    on: { "submit:ok": _vm.handleUpdateCustomer },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "invoice", lazy: "", label: "Công nợ" } },
                [
                  _c("apartment-invoice", {
                    attrs: {
                      customer: _vm.form.customer,
                      buildingId: _vm.buildingId,
                      apartmentId: _vm.form.id,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Đóng")]
            ),
            _vm.status == "update"
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      icon: "el-icon-delete",
                      loading: _vm.loadingDelete,
                    },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v("Xóa")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.tab == "info"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        _vm.status == "create"
                          ? _vm.createData()
                          : _vm.updateData()
                      },
                    },
                  },
                  [_vm._v("Đồng ý")]
                )
              : _c("div"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }