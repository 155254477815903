var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center box" }, [
    _c("img", { attrs: { width: "50", src: _vm.icon } }),
    _c("br"),
    _c("p", { staticClass: "font-bold size-large primary-color" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }