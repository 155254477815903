"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContractType = exports.ContractTagOptions = exports.ContractStatusTrans = exports.ContractStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _ContractStatusTrans, _ContractTagOptions;
var ContractType;
exports.ContractType = ContractType;
(function (ContractType) {
  ContractType["Renew"] = "RENEW";
  ContractType["Stop"] = "STOP";
  ContractType["TransferSoon"] = "TRANSFER_SOON";
  ContractType["Official"] = "OFFICIAL";
  ContractType["Prepay"] = "PREPAY";
})(ContractType || (exports.ContractType = ContractType = {}));
var ContractStatus;
exports.ContractStatus = ContractStatus;
(function (ContractStatus) {
  ContractStatus["Pending"] = "PENDING";
  ContractStatus["Complete"] = "COMPLETE";
  ContractStatus["Cancel"] = "CANCEL";
  ContractStatus["Signed"] = "SIGNED";
})(ContractStatus || (exports.ContractStatus = ContractStatus = {}));
var ContractStatusTrans = (_ContractStatusTrans = {}, (0, _defineProperty2.default)(_ContractStatusTrans, ContractStatus.Pending, 'Mới tạo'), (0, _defineProperty2.default)(_ContractStatusTrans, ContractStatus.Complete, 'Đã thanh lý'), (0, _defineProperty2.default)(_ContractStatusTrans, ContractStatus.Cancel, 'Đã hủy'), (0, _defineProperty2.default)(_ContractStatusTrans, ContractStatus.Signed, 'Đã ký'), _ContractStatusTrans);
exports.ContractStatusTrans = ContractStatusTrans;
var ContractTagOptions = (_ContractTagOptions = {}, (0, _defineProperty2.default)(_ContractTagOptions, ContractStatus.Pending, {
  type: "",
  effect: "dark"
}), (0, _defineProperty2.default)(_ContractTagOptions, ContractStatus.Complete, {
  type: "success",
  effect: "dark"
}), (0, _defineProperty2.default)(_ContractTagOptions, ContractStatus.Signed, {
  type: "warning",
  effect: "dark"
}), (0, _defineProperty2.default)(_ContractTagOptions, ContractStatus.Cancel, {
  type: "danger",
  effect: "dark"
}), _ContractTagOptions);
exports.ContractTagOptions = ContractTagOptions;