var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-type-chart" },
    [
      _c("bar-chart", {
        ref: "chart",
        staticStyle: { "margin-top": "16px" },
        attrs: {
          legend: _vm.legend,
          xAxisValue: _vm.xAxisValue,
          series: _vm.series,
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center", staticStyle: { "margin-top": "16px" } },
      [_c("label", { attrs: { for: "" } }, [_vm._v("Biểu đồ đơn mới, đơn cũ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }