"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var bannerApi = {
  findAll: findAll,
  create: create,
  update: update,
  remove: remove
};
exports.bannerApi = bannerApi;
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/v1/admin/banner",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/v1/admin/banner",
    method: "post",
    data: data
  });
}
function update(newsId, data) {
  return (0, _request.default)({
    url: "/v1/admin/banner/".concat(newsId, "/update"),
    method: "post",
    data: data
  });
}
function remove(newsId) {
  return (0, _request.default)({
    url: "/v1/admin/banner/".concat(newsId, "/delete"),
    method: "post"
  });
}