var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-mask", class: { active: _vm.isFocus } },
    [
      _c("div", { staticClass: "prep-end" }, [_vm._t("prepend")], 2),
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: {
                  alias: "decimal",
                  groupSeparator: ".",
                  rightAlign: false,
                  autoGroup: true,
                  repeat: 20,
                  autoUnmask: true,
                  allowMinus: false,
                },
                expression:
                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
              },
            ],
            staticClass: "el-input__inner",
            attrs: { type: "text" },
            domProps: { value: _vm.value },
            on: {
              input: _vm.handleInput,
              focus: _vm.handleFocus,
              blur: _vm.handleBlur,
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }