var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật tòa nhà" : "Thêm mới tòa nhà",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        fullscreen: _vm.status == "update",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "info", label: "Thông tin" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên tòa nhà", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đỉa chỉ", prop: "address" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    [
                      _c(
                        "label",
                        {
                          staticStyle: { color: "black", "font-size": "18px" },
                          attrs: { for: "" },
                        },
                        [_vm._v("Gửi xe")]
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Xe máy" } },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                        },
                                        expression:
                                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                      },
                                    ],
                                    model: {
                                      value: _vm.form.bikeParkPrice,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "bikeParkPrice", $$v)
                                      },
                                      expression: "form.bikeParkPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Xe điện" } },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                        },
                                        expression:
                                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                      },
                                    ],
                                    model: {
                                      value: _vm.form.electricParkPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "electricParkPrice",
                                          $$v
                                        )
                                      },
                                      expression: "form.electricParkPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tình trạng" } },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block" },
                        attrs: {
                          value: _vm.form.status == _vm.BuildingStatus.Open,
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": "Đang hoạt động",
                          "inactive-text": "Ngừng hoạt động",
                        },
                        on: { change: _vm.changeStatus },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hình ảnh", prop: "image" } },
                    [
                      _c("single-image", {
                        attrs: {
                          width: 300,
                          height: 150,
                          imagePath: _vm.form.image,
                          uploadUrl: "/v1/admin/building/upload",
                        },
                        on: { success: _vm.setImage },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.status == "update"
            ? [
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      lazy: "",
                      name: "apartments",
                      label: "Danh sách phòng, căn hộ",
                    },
                  },
                  [
                    _c("building-apartment", {
                      attrs: { buildingId: _vm.form.id },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { lazy: "", name: "services", label: "Dịch vụ" } },
                  [
                    _c("building-service", {
                      attrs: { buildingId: _vm.form.id },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      name: "staffs",
                      lazy: "",
                      label: "Danh sách nhân viên",
                    },
                  },
                  [
                    _c("building-staff", {
                      attrs: { buildingId: _vm.form.id },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      name: "customer-feedback",
                      lazy: "",
                      label: "Phản hồi khách hàng, cư dân",
                    },
                  },
                  [
                    _c("customer-feedback-tab", {
                      attrs: { buildingId: _vm.form.id },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.tab == "info"
            ? [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                          },
                        },
                      },
                      [_vm._v("Đóng")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", loading: _vm.loading },
                        on: { click: _vm.deleteData },
                      },
                      [_vm._v("Xóa")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            _vm.status == "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("Đồng ý")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }