"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuildingStatusTrans = exports.BuildingStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _BuildingStatusTrans;
var BuildingStatus;
exports.BuildingStatus = BuildingStatus;
(function (BuildingStatus) {
  BuildingStatus["Open"] = "OPEN";
  BuildingStatus["Close"] = "CLOSE";
})(BuildingStatus || (exports.BuildingStatus = BuildingStatus = {}));
var BuildingStatusTrans = (_BuildingStatusTrans = {}, (0, _defineProperty2.default)(_BuildingStatusTrans, BuildingStatus.Close, 'Ngưng hoạt động'), (0, _defineProperty2.default)(_BuildingStatusTrans, BuildingStatus.Open, 'Đang hoạt động'), _BuildingStatusTrans);
exports.BuildingStatusTrans = BuildingStatusTrans;