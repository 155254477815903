var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "staff-item", on: { click: _vm.onClick } }, [
    _vm.visibleSelect
      ? _c("div", { staticClass: "select-box" }, [
          !_vm.isSelected
            ? _c("img", {
                attrs: { width: "20", src: _vm.unCheckImg, alt: "" },
              })
            : _c("img", { attrs: { width: "20", src: _vm.checkImg, alt: "" } }),
        ])
      : _vm._e(),
    _c("div", { staticClass: "avatar" }, [
      _c("img", {
        staticStyle: { "border-radius": "50%" },
        attrs: {
          src: _vm.staff.avatar
            ? _vm.$url.image(_vm.staff.avatar)
            : _vm.avatarDefault,
          alt: "",
        },
      }),
    ]),
    _c("div", { staticClass: "info" }, [
      _c("label", { staticClass: "name", attrs: { for: "" } }, [
        _vm._v(" " + _vm._s(_vm.staff.name) + " "),
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.staff.phone) + " ")]),
      _c("span", [_vm._v(" " + _vm._s(_vm.staff.role.description) + " ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }