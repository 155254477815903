var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Sự cố khẩn cấp" } },
            [_c("emergency-tab", { attrs: { buildingId: _vm.buildingId } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Báo lỗi dịch vụ" } },
            [
              _c("trouble-service-tab", {
                attrs: { buildingId: _vm.buildingId },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Yêu cầu sửa chữa" } },
            [
              _c("repair-request-tab", {
                attrs: { buildingId: _vm.buildingId },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Gia hạn hợp đồng" } },
            [
              _c("form-continue-tab", {
                attrs: { buildingId: _vm.buildingId },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Chấm dứt hợp đồng" } },
            [
              _c("form-end-tab", {
                attrs: {
                  buildingId: _vm.buildingId,
                  type: _vm.StopContractType.Stop,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Trả phòng" } },
            [
              _c("form-end-tab", {
                attrs: {
                  buildingId: _vm.buildingId,
                  type: _vm.StopContractType.CheckOut,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Chuyển phòng" } },
            [
              _c("form-end-tab", {
                attrs: {
                  buildingId: _vm.buildingId,
                  type: _vm.StopContractType.ChangeApartment,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Ký gửi" } },
            [_c("transfer-tab", { attrs: { buildingId: _vm.buildingId } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }