"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InvoiceTagOptions = exports.InvoiceStatusTrans = exports.InvoiceStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _InvoiceStatusTrans, _InvoiceTagOptions;
var InvoiceStatus;
exports.InvoiceStatus = InvoiceStatus;
(function (InvoiceStatus) {
  InvoiceStatus["Draff"] = "DRAFF";
  InvoiceStatus["Pending"] = "PENDING";
  InvoiceStatus["Delay"] = "DELAY";
  InvoiceStatus["Confirm"] = "CONFIRM";
  InvoiceStatus["Complete"] = "COMPLETE"; //Khách đã thanh toán
})(InvoiceStatus || (exports.InvoiceStatus = InvoiceStatus = {}));
var InvoiceStatusTrans = (_InvoiceStatusTrans = {}, (0, _defineProperty2.default)(_InvoiceStatusTrans, InvoiceStatus.Draff, 'Đã tạo nháp'), (0, _defineProperty2.default)(_InvoiceStatusTrans, InvoiceStatus.Pending, 'Chưa thanh toán'), (0, _defineProperty2.default)(_InvoiceStatusTrans, InvoiceStatus.Delay, 'Thanh toán chậm'), (0, _defineProperty2.default)(_InvoiceStatusTrans, InvoiceStatus.Complete, 'Đã thanh toán'), (0, _defineProperty2.default)(_InvoiceStatusTrans, InvoiceStatus.Confirm, 'Khách đã xác nhận thanh toán'), _InvoiceStatusTrans);
exports.InvoiceStatusTrans = InvoiceStatusTrans;
var InvoiceTagOptions = (_InvoiceTagOptions = {}, (0, _defineProperty2.default)(_InvoiceTagOptions, InvoiceStatus.Draff, {
  type: "info",
  effect: "plain"
}), (0, _defineProperty2.default)(_InvoiceTagOptions, InvoiceStatus.Pending, {
  type: "",
  effect: "plain"
}), (0, _defineProperty2.default)(_InvoiceTagOptions, InvoiceStatus.Delay, {
  type: "warning",
  effect: "plain"
}), (0, _defineProperty2.default)(_InvoiceTagOptions, InvoiceStatus.Complete, {
  type: "success",
  effect: "plain"
}), _InvoiceTagOptions);
exports.InvoiceTagOptions = InvoiceTagOptions;