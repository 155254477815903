"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/209web_admin-chung-cu/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formStopApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var formStopApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/formStopContract",
      params: params
    });
  },
  complete: function complete(formStopContractId) {
    return (0, _request.default)({
      url: "/v1/admin/formStopContract/".concat(formStopContractId, "/complete"),
      method: "post"
    });
  }
};
exports.formStopApi = formStopApi;