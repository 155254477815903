var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "create" ? "Thêm mới nhân viên" : "Cập nhật nhân viên",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-position": "top", rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Username", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.status == "create"
                ? _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mật khẩu", prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "SDT", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { size: "medium" },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quyền" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "medium", clearable: "" },
                          model: {
                            value: _vm.form.roleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roleId", $$v)
                            },
                            expression: "form.roleId",
                          },
                        },
                        _vm._l(_vm.roles, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.description, value: item.id },
                          })
                        }),
                        1
                      ),
                      _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v("Quyền này không ảnh hưởng tới quyền trên app"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Avatar", prop: "avatar" } },
            [
              _c("single-image", {
                attrs: {
                  width: 200,
                  height: 100,
                  imagePath: _vm.form.avatar,
                  uploadUrl: "/v1/admin/staff/upload",
                },
                on: {
                  "upload:success": function (path) {
                    return _vm.$set(_vm.form, "avatar", path)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _vm.status != "create"
          ? _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "font-bold font-size--medium",
                    attrs: {
                      type: !_vm.form.isBlock ? "danger" : "warning",
                      size: "default",
                      icon: _vm.form.isBlock
                        ? "el-icon-unlock"
                        : "el-icon-lock",
                    },
                    on: { click: _vm.handleBlock },
                  },
                  [_vm._v(_vm._s(!_vm.form.isBlock ? "Khóa" : "Mở khóa"))]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "font-bold font-size--medium",
                    attrs: {
                      size: "medium",
                      type: "warning",
                      loading: _vm.loadingReset,
                    },
                    on: { click: _vm.handleResetPwd },
                  },
                  [_vm._v("Reset mật khẩu")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "font-bold font-size--medium",
                    attrs: { type: "danger", size: "default" },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v("Xóa")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {},
          [
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                attrs: { size: "medium" },
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Đóng")]
            ),
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                attrs: {
                  type: "primary",
                  size: "medium",
                  loading: _vm.loadingSubmit,
                },
                on: {
                  click: function ($event) {
                    _vm.status == "create" ? _vm.createData() : _vm.updateData()
                  },
                },
              },
              [_vm._v("Lưu")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }