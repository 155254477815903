var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm dịch vụ",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c(
              "section",
              [
                _c("h3", [_vm._v(" Đã chọn ")]),
                _vm._l(_vm.selectedActiveServices, function (item) {
                  return _c("service-item", {
                    key: item.id,
                    attrs: {
                      service: item,
                      isSelected: true,
                      visibleSelect: true,
                    },
                    on: { onClick: _vm.handleClickService },
                  })
                }),
              ],
              2
            ),
          ]),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _vm.services.length
              ? _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  },
                  [
                    _c("h3", [_vm._v(" Kết quả tìm ")]),
                    _vm._l(_vm.services, function (item, index) {
                      return _c("service-item", {
                        key: index,
                        attrs: {
                          service: item,
                          visibleSelect: true,
                          isSelected:
                            _vm.selectedActiveServices.findIndex(function (e) {
                              return e.id == item.id
                            }) > -1,
                        },
                        on: { onClick: _vm.handleClickService },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.selectedServices.length,
                loading: _vm.loadingSubmit,
              },
              on: { click: _vm.handleChoose },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }