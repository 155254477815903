var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "forgot-password" }, [
        _vm._v("Enter Password"),
      ]),
      _c("p", { staticClass: "content" }, [
        _vm._v(" Enter your new password to complete change password. "),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit($event)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Email" } },
            [
              _c("el-input", {
                attrs: { value: _vm.email, disabled: "", size: "medium" },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "New Password", prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: _vm.showPass ? "text" : "password",
                  size: "medium",
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
              _c(
                "div",
                { staticClass: "show-pwd" },
                [
                  !_vm.showPass
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "eye" },
                        on: {
                          click: function ($event) {
                            _vm.showPass = true
                          },
                        },
                      })
                    : _c("font-awesome-icon", {
                        attrs: { icon: "eye-slash" },
                        on: {
                          click: function ($event) {
                            _vm.showPass = false
                          },
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Confirm New Password", prop: "cfPassword" } },
            [
              _c("el-input", {
                attrs: {
                  size: "medium",
                  type: _vm.showConfirmPass ? "text" : "password",
                },
                model: {
                  value: _vm.form.cfPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cfPassword", $$v)
                  },
                  expression: "form.cfPassword",
                },
              }),
              _c(
                "div",
                { staticClass: "show-pwd" },
                [
                  !_vm.showConfirmPass
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "eye" },
                        on: {
                          click: function ($event) {
                            _vm.showConfirmPass = true
                          },
                        },
                      })
                    : _c("font-awesome-icon", {
                        attrs: { icon: "eye-slash" },
                        on: {
                          click: function ($event) {
                            _vm.showConfirmPass = false
                          },
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "btn-reset",
          attrs: { type: "primary", size: "", loading: _vm.loading },
          on: { click: _vm.handleSubmit },
        },
        [_vm._v("Re-new password")]
      ),
      _c(
        "div",
        { staticClass: "text-center", staticStyle: { "margin-top": "16px" } },
        [_c("a", { on: { click: _vm.handleBack } }, [_vm._v("Back")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }